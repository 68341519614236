import * as api from '@/api/publications_networks.js';

export default {
	namespaced: true,
	state: { 
		publication_id:null, 
		network_id:null,
		type_public:null,
		date_public:null, 
		cost:0, 
		images:[],
		video:[],
		request:null, 
		signature:null,
		required_fields:{},
		status:null,
		file_load_status:true,
		hashtags:[],
		delete:{
			status:false,
		},
		edit_image_video:{
			show:false,
			type:null,
			path:null,
			file_key:null,
			editor:[], 
			key_select_text:null
		}
	},
	getters: {
		publications_networks_create:state=>{
			return state
		},  
	},
	mutations: { 
		setData(state, data){ 
			state.publication_id = data.publication_id;		
			state.network_id = data.network_id; 
			state.type_public = data.type_public; 
			state.date_public = data.date_public; 
			state.cost = data.cost; 
			state.images = data.images;
			state.video = data.video; 
			state.request = data.request;
			state.signature = data.signature; 
			state.status = data.status;  
		}, 
		setHashTag(state, hashtags){ 
			state.hashtags = hashtags; 
		},
		setPublicationId(state, publication_id){
			state.publication_id = publication_id; 
		}, 
		setNetworkId(state, network_id){
			state.network_id = network_id;
		},   		 
		setTypePublic(state, type){
			state.type_public = type;
		},  
		setRequest(state, request){
			state.request = request;
		},
		setFileLoadStatus(state, status){
			state.file_load_status = status;
		},
		showDelete(state, data){
			state.delete.status = data.status;
		},
		setRequiredFields(state, required_fields){
			state.required_fields = required_fields;
		},
		setEditImageVideoShow(state, data){
			state.edit_image_video.show = data.status
			state.edit_image_video.type = data.type 
			state.edit_image_video.path = data.path  
			state.edit_image_video.file_key = data.file_key   
			state.edit_image_video.editor = data.editor   
			state.edit_image_video.key_select_text = data.key_select_text   
			 
		},
		setEditImageVideoNewText(state){
			state.edit_image_video.editor.push({
				text:"Новый текст",
				font_size:30,
				color:"#000000",
				x:0,
				y:0,
			})
		},
		deleteEditImageVideoNewText(state){
			let key = state.edit_image_video.key_select_text;
			state.edit_image_video.editor.splice(key, 1);
			state.edit_image_video.key_select_text = null;
		}, 
		setEditImageVideoKeySelectText(state, key_select_text){
			state.edit_image_video.key_select_text = key_select_text   
		},
		setEditImageVideoFontSize(state, font_size){	
			state.edit_image_video.editor[state.edit_image_video.key_select_text].font_size = font_size
		},		
		setEditImageVideoFontColor(state, color){	
			state.edit_image_video.editor[state.edit_image_video.key_select_text].color = color
		},
		setEditImageVideoXY(state, data){
			state.edit_image_video.editor[state.edit_image_video.key_select_text].x = data.x
			state.edit_image_video.editor[state.edit_image_video.key_select_text].y = data.y
		}, 
		setSignatureStatus(state, status){
			state.signature.status = status;
		}
	},
	actions: { 
		async loadPublication({ commit }, data){ 			 
			await api.getPublication(data).then((response)=>{
				if(response.result && response.data !== null) 
					commit('setData', response.data);	  
			})	 
		}, 
		async loadHashtag({ commit }){ 			 
			await api.getHashtag().then((response)=>{
				if(response.result) 
					commit('setHashTag', response.hashtags);	  
			})	 
		},
		async createPublication({ commit, dispatch }){

			let formData = new FormData();  
			formData.append("action", "add");

			await api.upPublication(formData).then((response)=>{
				if(response.result){ 
					commit('setPublicationId', response.publication_id);	 	
					dispatch("loadPublication", { type:"one", id:response.publication_id })	
				}	 
			})	 
		},
		async changePublication({ state }, params){ 

			let value = state[params];

			if(params == 'date_public'){  
				value = value.toISOString();
			}
			 
			if(params == "network_id") value = JSON.stringify(state.network_id)
			console.log(params, value);
			let formData = new FormData();  
			formData.append("action", "up");
			formData.append("id", state.publication_id);
			formData.append("params", params);
			formData.append("value", value);
			formData.append("request_status", false);
			formData.append("file", false);
			//{ action:"up", id:state.publication_id, params, value:state[params], request_status:false, file:false }
			await api.upPublication(formData) 
		},
		async changePublicationRequest({ state }, params){ 
			
			let formData = new FormData();  
			formData.append("action", "up");
			formData.append("id", state.publication_id);
			formData.append("params", params);
			formData.append("value", state.request[params]);
			formData.append("request_status", true);
			formData.append("file", false); 
			await api.upPublication(formData) 
		},
		async changePublicationSignature({ state }){
			let formData = new FormData();  
			formData.append("signature_text", state.signature.text); 
			await api.upSignature(formData) 
		},
		async setSignatureStatus({ commit }, status){
			let formData = new FormData();  
			formData.append("signature_status", status); 
			await api.upSignature(formData).then((response)=>{
				if(response.result){
					commit("setSignatureStatus", status)
				}
			}) 
		}, 
		async changePublicationRequestFile({ state }, data){  

			let formData = new FormData();  
			formData.append("action", "up");
			formData.append("id", state.publication_id);
			formData.append("params", data.params);
			formData.append("file_key", data.key);
			formData.append("value", state.request.files[data.key][data.params]);
			formData.append("request_status", true);
			formData.append("file", true);
			//{ action:"up", id:state.publication_id, params:data.params, file_key:data.key, value:state.request.files[data.key][data.params], request_status:true, file:true }
			await api.upPublication(formData) 
		}, 
		async sendPublication({ state, dispatch }){
			
			let check = false;
			let message = state.required_fields.message;
			let condition = state.required_fields.condition;
			let fields = state.required_fields.fields;
			let request = state.request;
  
			for(let key in fields){
				if(fields[key].value == "not_null"){
					if(request.hasOwnProperty(fields[key].params) && request[fields[key].params] !== null && request[fields[key].params].length > 0)
						fields[key].check = true
					else 
						fields[key].check = false
				}
				if(fields[key].value == "not_empty"){
					if(request.hasOwnProperty(fields[key].params) && request[fields[key].params].length > 0)
						fields[key].check = true
					else
						fields[key].check = false
				}
			}

			if(condition == "one")
				for(let key in fields)
					if(fields[key].check)
						check = true
			
			if(condition == "many"){
				let many_check = true;

				for(let key in fields)
					if(!fields[key].check)
						many_check = false

				check = many_check
			}	
  
			console.log(request);

			if(request.hasOwnProperty("link") && request.link !== null && request.link !== ""){
				let pattern = /^(http|https):\/\/([\w-]+(\.[\w-]+)+)(\/[\w-./?%&=]*)?$/; 
				if(!pattern.test(request.link)){
					check = false;
					message = "Ссылка должна быть в формате https://pzn.su"
				} 
			}

			if(request.hasOwnProperty("files") && request.files.length > 0)
				for(let key in request.files)
					if(request.files[key].hasOwnProperty("link") && request.files[key].link !== null && request.files[key].link !== ""){
						let pattern = /^(http|https):\/\/([\w-]+(\.[\w-]+)+)(\/[\w-./?%&=]*)?$/; 
						if(!pattern.test(request.files[key].link)){
							check = false;
							message = "Ссылка должна быть в формате https://pzn.su"
						} 
					}	

			console.log(check, request);

			if(check){
				await api.sendPublication({id:state.publication_id}).then((response)=>{
					if(response.result)
						dispatch("nullableData");			
				})
			}
			else
				dispatch("snackBar/showSnackBar", message, {root:true})

			return check;	 
		},
		async loadFiles({ state, commit, dispatch }, data){ 
 
			let formData = new FormData();  
			formData.append('publication_id', state.publication_id);
			formData.append('type', data.type);
			formData.append('params', data.params); 
			data.files.forEach(file=>{
				formData.append("files[]", file);
			}); 
			
			commit("setFileLoadStatus", false)

			let response = await api.loadFilesPublication(formData);
 
			if(response.result){
				dispatch("loadPublication", { type:"one", id:state.publication_id })	
				commit("setFileLoadStatus", true)	
			}

			return response.result
		},
		async deleteFile({ state, dispatch }, data){ 
			await api.deleteFilePublication({ id:state.publication_id, path:data.path, type:data.type, params:data.params }).then((response)=>{
				if(response.result)
					dispatch("loadPublication", { type:"one", id:state.publication_id })
			}); 
		},
		async deletePublication({ state, commit, dispatch }){
			await api.deletePublication({ id:state.publication_id }).then((response)=>{
				if(response.result) {
					dispatch("nullableData");
					commit("showDelete", { status:false })
				}				 
			})	 
		},
		async saveEditImageVideo({state, dispatch}){

			let formData = new FormData();  
			formData.append("action", "up");
			formData.append("id", state.publication_id);
			formData.append("params", "editor");
			formData.append("value", JSON.stringify(state.edit_image_video.editor));
			formData.append("request_status", true);
			formData.append("file", true);
			formData.append("file_key", state.edit_image_video.file_key);
			
			await api.upPublication(formData).then((response)=>{
				if(response.result){
					dispatch("loadPublication", { type:"one", id:state.publication_id }) 
					dispatch("snackBar/showSnackBar", "Обновлено!", {root:true})
				}
			}) 
		},
		async addHashtag({ dispatch }){ 			 
			await api.addHashtag({ type:"add" }).then((response)=>{
				if(response.result) 
					dispatch('loadHashtag');	  
			})	 
		},
		async upHashtag({ state, dispatch }, key){ 		
			await api.addHashtag({ type:"up", id:state.hashtags[key].id, name:state.hashtags[key].name }).then((response)=>{
				if(response.result) 
					dispatch('loadHashtag');	  
			})	 
		},
		async deleteHashtag({ dispatch }, id){ 			 
			await api.deleteHashtag({ id }).then((response)=>{
				if(response.result) 
					dispatch('loadHashtag');	  
			})	 
		}, 
		nullableData({ commit }){
			commit('setData', {
				publication_id:null, 
				network_id:null,
				type_public:null,
				date_public:null,
				cost:0,
				images:[],
				video:[],
				request:null,
				required_fields:{},
				status:null,
				signature:null,
				data:null
			});	 
		}
	}
} 