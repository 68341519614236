import * as api from '@/api/track_analytic_report.js';

export default {
	namespaced: true,
	state: {
		filter:{
			comparison:false,
			items:[]
		},
		list_sources:null,
		list_site:null,
		data:[],
		data_message:null,
		load_status:{
			type:null,
			status:true,
			key_source:null,
			key_medium:null,
			key_campaign:null,
			key_content:null,
		},	 
		companies:{
			show:false,
			show_card:false,
			costs_details:false,
			key_card:null,
			list_companies:[],
			companies:[],
			sort_list_companies:{
				id:'desc',
				name:'По убыванию'
			}
		}
	},
	getters: {
		track_analytic_report:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, data){
			state.filter = data.filter;
			state.list_sources = data.list_sources;
			state.list_site = data.list_site;
		},
		setSource(state, data){
			state.data = data.data; 
			state.data_message = data.message; 
		},
		setMedium(state, data){
			state.data[data.key_source].utm_medium = data.utm_medium;  
		},
		setCampaign(state, data){
			state.data[data.key_source].utm_medium[data.key_medium].utm_campaign = data.utm_campaign;  
		},  
		setContent(state, data){
			state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content = data.utm_content;  
		},   
		setTerm(state, data){
			state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].utm_term = data.utm_term;  
		},   
		setFilterItem(state){  
			state.filter.items.push({ 
				period:null, 
				date:null,
				source_id:null,
				medium_id:null,
				campaign_id:null,
				content_id:null,
			 });
		}, 
		setFilterItemValue(state, data){
			state.filter.items[data.key][data.param] = data.value
		},
		spliceFilterItem(state, key){
			state.filter.items.splice(key, 1);
		},
		setFilterItemNullable(state){
			state.filter.items = [];
		},  
		setFilterComparison(state, val){
			state.filter.comparison = val
		},
		setShowUtm(state, data){
			if(data.type == "source")
				state.data[data.key_source].show = data.status
			if(data.type == "medium")
				state.data[data.key_source].utm_medium[data.key_medium].show = data.status
			if(data.type == "campaign")
				state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].show = data.status
			if(data.type == "content")
				state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].show = data.status
		},
		setLoadStatus(state, data){
			state.load_status.type = data.type
			state.load_status.status = data.status
			state.load_status.key_source = data.key_source
			state.load_status.key_medium = data.key_medium
			state.load_status.key_campaign = data.key_campaign
			state.load_status.key_content = data.key_content
		},
		setShowCompanies(state, data){
			state.companies.show = data.status
			state.companies.list_companies = data.list_companies
			state.companies.companies = data.companies
			state.companies.sort_list_companies.id = 'desc'
			state.companies.sort_list_companies.name ='По убыванию'
		},
		setShowCardCompanies(state, data){
			state.companies.show_card = data.status
			state.companies.key_card = data.key
			if(!data.status)
				state.companies.costs_details = false
		},
		setCostsDetailsCardCompanies(state, status){
			state.companies.costs_details = status
		},
		setSortListCompanies(state, data){

			state.companies.sort_list_companies.id = data.id
			state.companies.sort_list_companies.name = data.name
			
			if(data.id == "desc"){
				state.companies.list_companies.sort(function(a,b){ 
					return new Date(a.date) - new Date(b.date)
				});
				state.companies.companies.sort(function(a,b){ 
					return new Date(a.date) - new Date(b.date)
				});
			}

			if(data.id == "asc"){
				state.companies.list_companies.sort(function(a,b){ 
					return new Date(b.date) - new Date(a.date)
				});
				state.companies.companies.sort(function(a,b){ 
					return new Date(b.date) - new Date(a.date)
				});
			}
		},
		sort(state, data){
			 
			if(data.key_source !== null && data.key_medium == null && data.key_campaign == null && data.key_content == null){
				
				if(state.data[data.key_source].sort == null) state.data[data.key_source].sort = "desc";
					else if(state.data[data.key_source].sort == "desc") state.data[data.key_source].sort = "asc"; 
						else if(state.data[data.key_source].sort == "asc") state.data[data.key_source].sort = "desc"; 
 
				state.data[data.key_source].utm_medium.sort(function(a, b) {
					
					if(state.data[data.key_source].sort == "asc")
						return a[data.index] - b[data.index];

					if(state.data[data.key_source].sort == "desc")
						return b[data.index] - a[data.index];
				});
			}

			if(data.key_source !== null && data.key_medium !== null && data.key_campaign == null && data.key_content == null){
				
				if(state.data[data.key_source].utm_medium[data.key_medium].sort == null) state.data[data.key_source].utm_medium[data.key_medium].sort = "desc";
					else if(state.data[data.key_source].utm_medium[data.key_medium].sort == "desc") state.data[data.key_source].utm_medium[data.key_medium].sort = "asc"; 
						else if(state.data[data.key_source].utm_medium[data.key_medium].sort == "asc") state.data[data.key_source].utm_medium[data.key_medium].sort = "desc"; 
 
				state.data[data.key_source].utm_medium[data.key_medium].utm_campaign.sort(function(a, b) {
					
					if(state.data[data.key_source].utm_medium[data.key_medium].sort == "asc")
						return a[data.index] - b[data.index];

					if(state.data[data.key_source].utm_medium[data.key_medium].sort == "desc")
						return b[data.index] - a[data.index];
				});
			}

			if(data.key_source !== null && data.key_medium !== null && data.key_campaign !== null && data.key_content == null){
				
				if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == null) state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort = "desc";
					else if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == "desc") state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort = "asc"; 
						else if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == "asc") state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort = "desc"; 
 
						state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content.sort(function(a, b) {
					
					if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == "asc")
						return a[data.index] - b[data.index];

					if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].sort == "desc")
						return b[data.index] - a[data.index];
				});
			}

			if(data.key_source !== null && data.key_medium !== null && data.key_campaign !== null && data.key_content !== null){
				
				if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == null) state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort = "desc";
					else if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == "desc") state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort = "asc"; 
						else if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == "asc") state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort = "desc"; 
 
				state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].utm_term.sort(function(a, b) {
					
					if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == "asc")
						return a[data.index] - b[data.index];

					if(state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].sort == "desc")
						return b[data.index] - a[data.index];
				});
			}
		 
		}	 
	},
	actions: { 
		async loadFilter({ commit, dispatch, rootGetters }){  
			await api.getFilter({
				user_id:rootGetters['account/account'].user_id, 
				page:"track_analytics_report"
			}).then((response)=>{
				if(response.result) {
					commit('setFilter', { filter:response.filter, list_sources:response.list_sources, list_site:response.list_site });	 
					dispatch('loadTrackAnalyticsSource');
				}				 
			})	 
		}, 
		async loadTrackAnalyticsSource({ state, commit, rootGetters }){  
			commit("setLoadStatus", { type:'source', status:false, key_source:null, key_medium:null, key_campaign:null, key_content:null }); 
			await api.getTrackAnalyticsSource({
				filter:state.filter, 
				user_id:rootGetters['account/account'].user_id,
				page:"track_analytics_report", 
			}).then((response)=>{
				if(response.result) {
					commit('setSource', { data:response.data, message:response.message });	 
					commit("setLoadStatus", { type:'source', status:true, key_source:null, key_medium:null, key_campaign:null, key_content:null  }); 
				}				 
			})	 
		}, 
		async setShowUtm({ state, commit }, data){  
		  
			if(data.type == "source" && data.status && state.data[data.key_source].utm_medium.length == 0){		
				commit("setLoadStatus", { type:'medium', status:false, key_source:data.key_source, key_medium:null, key_campaign:null, key_content:null  });
				let utm_source = state.data[data.key_source].utm_id
				let site_id = state.data[data.key_source].site_id
				let key_filter_items = state.data[data.key_source].key_filter_items

				await api.getTrackAnalyticsMedium({
					filter:state.filter, 
					utm_source,
					site_id,
					key_filter_items
				}).then((response)=>{
					if(response.result){
						commit('setMedium', { key_source:data.key_source, utm_medium:response.utm_medium });
						commit("setLoadStatus", { type:'medium', status:true, key_source:null, key_medium:null, key_campaign:null, key_content:null });
					}
				})
			}

			if(data.type == "medium" && data.status && state.data[data.key_source].utm_medium[data.key_medium].utm_campaign.length == 0){		
 
				commit("setLoadStatus", { type:'campaign', status:false, key_source:data.key_source, key_medium:data.key_medium, key_campaign:null, key_content:null  });
				let utm_source = state.data[data.key_source].utm_id
				let utm_medium = state.data[data.key_source].utm_medium[data.key_medium].utm_id
				let site_id = state.data[data.key_source].site_id
				let key_filter_items = state.data[data.key_source].key_filter_items

				await api.getTrackAnalyticsCampaign({
					filter:state.filter, 
					utm_source,
					utm_medium,
					site_id,
					key_filter_items
				}).then((response)=>{
					if(response.result){
						commit('setCampaign', { key_source:data.key_source, key_medium:data.key_medium, utm_campaign:response.utm_campaign });
						commit("setLoadStatus", { type:'campaign', status:true, key_source:null, key_medium:null, key_campaign:null, key_content:null });
					}
				})
			}

			if(data.type == "campaign" && data.status && state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content.length == 0){		
 
				commit("setLoadStatus", { type:'content', status:false, key_source:data.key_source, key_medium:data.key_medium, key_campaign:data.key_campaign, key_content:null  });
				let utm_source = state.data[data.key_source].utm_id
				let utm_medium = state.data[data.key_source].utm_medium[data.key_medium].utm_id
				let utm_campaign = state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_id
				let site_id = state.data[data.key_source].site_id
				let key_filter_items = state.data[data.key_source].key_filter_items

				await api.getTrackAnalyticsContent({
					filter:state.filter, 
					utm_source,
					utm_medium,
					utm_campaign,
					site_id,
					key_filter_items
				}).then((response)=>{
					if(response.result){
						commit('setContent', { key_source:data.key_source, key_medium:data.key_medium, key_campaign:data.key_campaign, utm_content:response.utm_content });
						commit("setLoadStatus", { type:'content', status:true, key_source:null, key_medium:null, key_campaign:null, key_content:null });
					}
				})
			}

			if(data.type == "content" && data.status && state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].utm_term.length == 0){		
 
				commit("setLoadStatus", { type:'term', status:false, key_source:data.key_source, key_medium:data.key_medium, key_campaign:data.key_campaign, key_content:data.key_content  });
				let utm_source = state.data[data.key_source].utm_id
				let utm_medium = state.data[data.key_source].utm_medium[data.key_medium].utm_id
				let utm_campaign = state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_id
				let utm_content = state.data[data.key_source].utm_medium[data.key_medium].utm_campaign[data.key_campaign].utm_content[data.key_content].utm_id
				let site_id = state.data[data.key_source].site_id
				let key_filter_items = state.data[data.key_source].key_filter_items

				await api.getTrackAnalyticsTerm({
					filter:state.filter, 
					utm_source,
					utm_medium,
					utm_campaign,
					utm_content,
					site_id,
					key_filter_items
				}).then((response)=>{
					if(response.result){
						commit('setTerm', { key_source:data.key_source, key_medium:data.key_medium, key_campaign:data.key_campaign, key_content:data.key_content, utm_term:response.utm_term });
						commit("setLoadStatus", { type:'term', status:true, key_source:null, key_medium:null, key_campaign:null, key_content:null });
					}
				})
			}

			commit('setShowUtm', data);
		},
		async setShowCompanies({ state, commit }, data){
			  
			if(data.status)
				await api.getCompanies({
					filter:state.filter,
					key_source:data.key_source, 
					utm_source:data.utm_source, 
					utm_medium:data.utm_medium, 
					utm_campaign:data.utm_campaign, 
					utm_content:data.utm_content, 
					utm_term:data.utm_term
				}).then((response)=>{
					if(response.result) {
						commit("setShowCompanies", {status:data.status, list_companies:response.list_companies, companies:response.companies })
					}				 
				})
			else
				commit("setShowCompanies", {status:data.status, list_companies:[], companies:[] })
		}
	}, 
} 