import * as api from '@/api/track_analytic_costs.js';

export default {
	namespaced: true,
	state: {
		filter:null,
		list_sources:null, 
		costs:[]
	},
	getters: {
		track_analytic_costs:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, data){
			state.filter = data.filter;
			state.list_sources = data.list_sources;  
		},    
		setFilterValue(state, data){
			state.filter[data.param] = data.value
		},   
		setCosts(state, costs){
			state.costs = costs
		},
		addCost(state, cost){
			state.costs.unshift(cost)
		},
		upCost(state, data){
			state.costs[data.key] = data.cost
		},
		deleteCost(state, key){
			state.costs.splice(key, 1)
		},		 
	},
	actions: { 
		async loadFilter({ commit, dispatch, rootGetters }){  
			await api.getFilter({
				user_id:rootGetters['account/account'].user_id, 
				page:"track_analytics_costs"
			}).then((response)=>{
				if(response.result) {
					commit('setFilter', { filter:response.filter, list_sources:response.list_sources});	 
					dispatch('loadTrackAnalyticsCosts');
				}				 
			})	 
		}, 
		async loadTrackAnalyticsCosts({ state, commit, rootGetters }){  
			 
			await api.getTrackAnalyticsCosts({
				filter:state.filter, 
				user_id:rootGetters['account/account'].user_id,
				page:"track_analytics_costs", 
			}).then((response)=>{
				if(response.result) {
					commit('setCosts', response.costs); 
				}				 
			})	 
		},  
		async upCost({ state, commit }, data){

			let id, cost, utm, date = null;		
			
			if(data.key !== null){ 
				 
				id = state.costs[data.key].id
				date = state.costs[data.key].date
				cost = state.costs[data.key].cost
				utm = { 
					utm_source:state.costs[data.key].utm_source.id,
					utm_medium:state.costs[data.key].utm_medium.id,
					utm_campaign:state.costs[data.key].utm_campaign.id,
					utm_content:state.costs[data.key].utm_content.id,
				}   
			}
			
			await api.getTrackAnalyticsUpCost({ action:data.action, date, id, cost, utm }).then((response)=>{
				if(response.result){
					if(data.key !== null)
						commit("upCost", { key:data.key, cost:response.cost })
					else
						commit("addCost", response.cost)						 
				}
			})
		}, 
		async deleteCost({ state, commit }, key){
			
			let id = state.costs[key].id

			await api.getTrackAnalyticsDeleteCost({ id }).then((response)=>{
				if(response.result){
					commit("deleteCost", key);
				}
			})
		}
	}, 
} 