import server from '@/api/http';

export async function getFilter(params){  
	let { data } = await server.get('marketing/track_analytic/report/filter/get', { params });
	return data;
}
export async function getTrackAnalyticsSource(params){  
	let { data } = await server.get('marketing/track_analytic/report/source/get', { params });
	return data;
} 
export async function getTrackAnalyticsMedium(params){  
	let { data } = await server.get('marketing/track_analytic/report/medium/get', { params });
	return data;
} 
export async function getTrackAnalyticsCampaign(params){  
	let { data } = await server.get('marketing/track_analytic/report/campaign/get', { params });
	return data;
} 
export async function getTrackAnalyticsContent(params){  
	let { data } = await server.get('marketing/track_analytic/report/content/get', { params });
	return data;
} 
export async function getTrackAnalyticsTerm(params){  
	let { data } = await server.get('marketing/track_analytic/report/term/get', { params });
	return data;
}  
export async function getCompanies(params){  
	let { data } = await server.get('marketing/track_analytic/report/companies/get', { params });
	return data;
} 
 