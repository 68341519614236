import server from '@/api/http';

export async function getFilter(params){  
	let { data } = await server.get('marketing/track_analytic/costs/filter/get', { params });
	return data;
}
export async function getTrackAnalyticsCosts(params){  
	let { data } = await server.get('marketing/track_analytic/costs/get', { params });
	return data;
} 
export async function getTrackAnalyticsUpCost(params){  
	let { data } = await server.get('marketing/track_analytic/cost/up', { params });
	return data;
} 
export async function getTrackAnalyticsDeleteCost(params){  
	let { data } = await server.get('marketing/track_analytic/cost/delete', { params });
	return data;
} 